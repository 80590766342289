//Necessário para customização de theme do Angular Material
@use '@angular/material' as mat;

//Import de font
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');

// Importando variáveis e mixins do Bootstrap
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins';

// Importando o Bootstrap
@import 'node_modules/bootstrap/scss/bootstrap';


//Customização de theme do Angular Material
// A principio, o theme só utiliza o valor 500, porém, sem os demais valores não builda - todo: estudar como funciona
$custom-primary-palette: (
  50: #ffffff,
  100: #ffffff,
  200: #ffffff,
  300: #ffffff,
  400: #ffffff,
  500: #068FFF,
  600: #ffffff,
  700: #ffffff,
  800: #ffffff,
  900: #ffffff,
  contrast: (
    50: #ffffff,
    100: #ffffff,
    200: #ffffff,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff
  )
);

$custom-accent-palette: (
  50: #ffffff,
  100: #ffffff,
  200: #ffffff,
  300: #ffffff,
  400: #ffffff,
  500: #27272A,
  600: #ffffff,
  700: #ffffff,
  800: #ffffff,
  900: #ffffff,
  contrast: (
    50: #ffffff,
    100: #ffffff,
    200: #ffffff,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff
  )
);

$custom-typography: mat.define-typography-config($font-family: "Work Sans, sans-serif");
$custom-primary: mat.define-palette($custom-primary-palette);
$custom-accent: mat.define-palette($custom-accent-palette);
$custom-warn: mat.define-palette($custom-accent-palette); //todo:

$custom-theme: mat.define-light-theme((
  color: (
    primary: $custom-primary,
    accent: $custom-accent,
    warn: $custom-warn,
  ),
  typography: $custom-typography,
));

@include mat.all-component-themes($custom-theme);

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Work Sans", sans-serif;
}

$accent-color: #27272A;

.icon-size-30 {
  width: 30px;
}

.flex-align-itens-center {
  display: flex;
  align-items: center;
}

.text-right {
  text-align: right;
}

.conjunto-avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: -10px;

  color: black;
  background: #fff;
}

.tipography {
  font-size: 20px;
  font-weight: 500;
  color: $accent-color;
  margin: 40px 0 30px 0;
}

button {
  background: #068FFF;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  padding: 5px 12px 5px 12px;
  border-radius: 3px;
  user-select: none;
}

.button-disabled {
  background-color: #BDBDBD !important;
  cursor: not-allowed !important;
  color: #F0F0F0 !important;
}

.button-disabled-mudar {
  background-color: #9d1212 !important;
  color: #9d1212 !important;
}

.button-geral-full {
  background-color: #068FFF;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  padding: 5px 12px 5px 12px;
  border-radius: 3px;
  width: 100%;
  text-align: center;
}

.button-white {
  background-color: #fafafa;
  color: #3F3F46;
  border: 2px solid #D1D1D1;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  padding: 5px 12px 5px 12px;
  border-radius: 4px;
}

.button-geral {
  //border: 2px solid #068FFF;
}

.button-no-border {
  background: transparent !important;
}

.button-no-border {
  background-color: #fafafa;
  color: #3F3F46;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  text-decoration: underline;
}

.current-page {
  font-weight: 600;
  background: #e9e9e9;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.tanque-infos {
  background: #f0f0f0 !important;
  border-radius: 20px;
  padding: 10px !important;
}

.form-label, .form-control, option, select{
  background-color: #fff !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
};

img {
  pointer-events: none;
  user-select: none;
}